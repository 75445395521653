import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const StyledNewsContainer = styled.div`
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  max-width: 83%;
  margin: 1rem auto;
  border: 8px solid black;
  justify-content: space-around;
  padding: 1rem;

  @media (max-width: 780px) {
    grid-template-columns: 100%;
  }

  .container {
    position: relative;

    border: 5px solid black;
    margin: 0.6rem 0.6rem;

    .img-container {
      height: 250px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .title-container {
    position: absolute;
    font-family: "Orbitron";
    max-width: 200px;
    text-align: center;
    margin: auto;
    border: 3px solid black;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 50%;
    top: 10px;
    left: 15px;
  }
`

const NewsTitle = styled.h1`
  font-family: "Orbitron";
  font-size: 1.1em;
  margin: 0;
  color: black;
`

const AllArticles = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulArticle {
        edges {
          node {
            title
            slug
            description
            datePosted(formatString: "MMMM Do, YYYY")
            featuredImage {
              fixed {
                width
                src
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledNewsContainer>
      {data.allContentfulArticle.edges.map(edge => {
        return (
          <Link
            style={{ textDecoration: "none" }}
            to={`/article/${edge.node.slug}`}
          >
            <div className="container">
              <div className="title-container">
                <NewsTitle>{edge.node.title}</NewsTitle>
              </div>
              <div className="img-container">
                <img src={edge.node.featuredImage.fixed.src} alt="" />
              </div>
            </div>
          </Link>
        )
      })}
    </StyledNewsContainer>
  )
}

export default AllArticles
