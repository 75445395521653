import React from "react"
import styled from "styled-components"
import AllArticles from "../components/AllArticles"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PageLayout = styled.div`
  max-width: 1400px;
  padding-top: 7rem;
`

const ArticlesPage = () => {
  return (
    <>
      <SEO
        title="articles"
        description="Nerdcore and Geek Rock news from Geek Life Radio"
      />
      <Layout>
        <PageLayout>
          <AllArticles />
        </PageLayout>
      </Layout>
    </>
  )
}

export default ArticlesPage
